/** @jsxImportSource @emotion/react */
import { Button, OverlayToaster, Toaster } from "@blueprintjs/core"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { isEmpty } from "lodash"
import { Dispatch, KeyboardEvent, SetStateAction, useCallback, useEffect } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"

import { FlexContainer, FlexContainerAndItem } from "../../../../styled/flexbox.styled"
import { SCIENT_AGGRID_COLORS, SCIENT_COLORS } from "../../../../styled/scientColors"
import { LargeText } from "../../../../styled/text.styled"
import { TextAreaAutosize } from "../../../components"
import { createToaster } from "../../../utils/createToaster"
import useTradingAccountsQuery from "../Portfolio/hooks/useTradingAccountsQuery"
import { isDefaultOrder, isWaveEmpty } from "../utils/checkEmptyWave"
import { convertWaveInputsToWave } from "../utils/formatSendWave"
import OrdersForm from "./OrdersForm"
import {
  addRowButtonCss,
  FormContainer,
  RationalAndSubmitContainer,
  RationalHeader,
} from "./WaveCreationForm.styled"
import SelectAccount from "./components/SelectAccount"
import { useCreateWaveMutation } from "./hooks"
import { ExecutionType, OrderType, Side } from "./types/common"
import { OrderInputs, WaveInputs } from "./types/form"
import { Wave } from "./types/wave"

export interface IWaveCreateForm {
  setSelectedWaveId: Dispatch<SetStateAction<string | number | undefined>>
}

export const defaultOrder: OrderInputs = {
  symbol: { ticker: "" },
  side: Side.BUY,
  order_type: OrderType.MARKET,
  execution_type: ExecutionType.HERE,
  target_end_datetime: "",
  execution_details: "",
}

// Create toaster instance
const toaster: Toaster = OverlayToaster.create({ position: "top" })

const WaveCreationForm = ({ setSelectedWaveId }: IWaveCreateForm) => {
  const queryClient = useQueryClient()
  const isSubmitting = false

  /**
   * Getting all accounts that user has access to
   */
  const { accounts, error: accountsError } = useTradingAccountsQuery({ enabled: true })

  useEffect(() => {
    if (accountsError) {
      toaster.show({
        message: "An error occurred while fetching your account, please contact us using intercom.",
        intent: "danger",
      })
    }
  }, [accountsError])

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    setValue,
    reset: resetWaveForm,
  } = useForm<WaveInputs>({
    defaultValues: {
      rational: "",
      orders: Array(5).fill(defaultOrder), // 5 orders rows by default
    },
    /**
     * With "onChange" mode, Validation is triggered on the changeevent for each input,
     * leading to multiple re-renders. Warning: this often comes with a significant impact on performance.
     * https://react-hook-form.com/docs/useform#mode
     */
    mode: "onChange",
  })

  const watchAccount = watch("account_id")

  /**
   * If there's only one account in the list, we select it
   */
  useEffect(() => {
    if (accounts.length === 1) {
      setValue("account_id", accounts[0].id)
    }
  }, [watchAccount, accounts, setValue])

  // Initialize orders array
  const {
    fields,
    append: appendOrder,
    remove: removeOrder,
    update: updateOrder,
  } = useFieldArray({
    control,
    name: "orders",
  })

  /**
   * Copy values from the previous order at the given index
   */
  const copyPreviousOrder = useCallback(
    (index: number) => {
      const watchOrders = watch("orders")
      const { id, ...previousOrder } = watchOrders[index - 1]
      if (previousOrder && !isDefaultOrder(previousOrder)) {
        updateOrder(index, previousOrder)
        trigger(`orders[${index}]`)
      }
    },
    [updateOrder, watch, trigger],
  )

  /**
   * Create/Update/NewRevision Wave Mutations
   */
  const createWaveMutation = useCreateWaveMutation({
    onSuccess: (wave: Wave) => {
      /**
       * When we receive the answer from back, we need to refetch the Waves
       * since the page changes and even if we would update the cache,
       * the infinite Hook used is not the same as the one used in the Waves page
       * Hence not firing a rerender
       * In order to just refetch the first page, we remove the query from the cache
       */
      queryClient.removeQueries(["waves"])
      setSelectedWaveId(wave.id)
      resetWaveForm()
    },
    onError: (error: AxiosError) => {
      //Set type assertion
      const errorData = error.response?.data as {
        error?: { __all__?: string[] }
      }
      // Retrieve custom error from Axios response or generic Axios error.message
      const errorMessage = errorData?.error?.__all__ || error.message
      createToaster({
        message: errorMessage ? `Error: ${errorMessage}` : "An error occurred",
        intent: "danger",
      })
    },
  })

  /**
   * Callback to submit Form
   */
  const submitForm = useCallback(
    (data: WaveInputs) => {
      const newWave = convertWaveInputsToWave(data)
      createWaveMutation.mutate(newWave)
    },
    [createWaveMutation],
  )
  // Function to not allow wave submission when user presses 'enter' key
  const handleKeyPress = (event: KeyboardEvent<HTMLFormElement>) => {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }

  return (
    <FormContainer onSubmit={handleSubmit(submitForm)} onKeyDown={handleKeyPress}>
      <FlexContainer flexDirection="column">
        <OrdersForm
          orders={fields}
          control={control}
          register={register}
          deleteOrder={removeOrder}
          copyPreviousOrder={copyPreviousOrder}
          errors={errors}
          watch={watch}
          trigger={trigger}
          setValue={setValue}
        />
        <Button
          text={<LargeText fontWeight="bold">+</LargeText>}
          onClick={() => appendOrder(defaultOrder)}
          css={addRowButtonCss}
        />
      </FlexContainer>

      <RationalAndSubmitContainer flexDirection="row-reverse" gap="7%" alignItems="center">
        <FlexContainer>
          <Button
            large
            type="submit"
            text={isSubmitting ? "Submitting..." : "Submit"}
            disabled={isSubmitting || !isEmpty(errors) || isWaveEmpty(watch("orders"))}
            intent="primary"
            icon="send-message"
          />
        </FlexContainer>

        <FlexContainerAndItem flexDirection="column" flexGrow={1}>
          <RationalHeader justifyContent="center">
            <LargeText color={SCIENT_AGGRID_COLORS.headerFont}>Rational</LargeText>
          </RationalHeader>
          <Controller
            name="rational"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextAreaAutosize
                value={value}
                handleChange={onChange}
                backgroundColor={`${SCIENT_COLORS.darkWhite} !important`}
                color={`${SCIENT_COLORS.black} !important`}
                minHeight="60px"
              />
            )}
          />
        </FlexContainerAndItem>
        <SelectAccount
          name="account_id"
          control={control}
          accounts={accounts}
          watch={watch}
          onChangeSelectedAccount={resetWaveForm}
          rules={{ required: "Account is required" }}
        />
      </RationalAndSubmitContainer>
    </FormContainer>
  )
}

export default WaveCreationForm
